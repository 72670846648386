import React from 'react';
import {connect} from 'react-redux';

import './styles.scss';
import Logo from 'assets/logo-dysmate.png';
import {isTablet} from 'react-device-detect';
import Gif from 'assets/rotation-from-horizontal-to-vertical.gif';
import {begin} from 'utils/beforeunload';

import Introduksjon from 'components/Introduksjon';
import Orddiktat from 'components/Orddiktat';
import Ordkjedetesten from 'components/Ordkjedetesten';
import Tulleordtesten from 'components/Tulleordtesten';
import Skrivehastighet from 'components/Skrivehastighet';
import Leseproven from 'components/Leseproven';
import Ordforstaelse from 'components/Ordforstaelse';
import Ferdig from 'components/Ferdig';

import getUserInfo from 'api/getUserInfo';
import getTestInfo from 'api/getTestInfo';
import setAnamneseVersion from 'api/setAnamneseVersion';
import {render} from 'react-dom';

var testSteps = [
	Introduksjon,
	Orddiktat,
	Ordkjedetesten,
	Tulleordtesten,
	Skrivehastighet,
	Leseproven,
	Ordforstaelse,
	Ferdig,
];
const renderRemainingTests = [];
let includeDetailedAnamnese = false;
class ScreeningTest extends React.Component {
	constructor() {
		super();

		this.state = {
			isLoggedIn: false,
			includeAnamnese: false,
			testsToTake: [],
			isPortrait: true,
			isRotateDeviceFirstTimeRendered: true,
		};
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		getTestInfo().then((testInfo) => {
			const missingTests = [];
			const exclusiveTests = testInfo.exclusiveTests;
			const testToContinue = Object.values(exclusiveTests);
			if (testToContinue.length) {
				for (let i = 0; i < testToContinue.length + 1; i++) {
					switch (testToContinue[i]) {
						case 1:
							renderRemainingTests.push(Orddiktat);
							break;
						case 2:
							renderRemainingTests.push(Ordkjedetesten);

							break;
						case 3:
							renderRemainingTests.push(Tulleordtesten);
							break;
						case 4:
							renderRemainingTests.push(Skrivehastighet);
							break;
						case 5:
							renderRemainingTests.push(Leseproven);

							break;
						case 6:
							renderRemainingTests.push(Ordforstaelse);

							break;
					}
					if (i === testToContinue.length) {
						renderRemainingTests.push(Ferdig);
					}
				}
				this.setState({testsToTake: missingTests});
			}
			setAnamneseVersion().then((response) => {
				if (response === 'True') {
					includeDetailedAnamnese = true;
					this.setState({includeAnamnese: true});
				} else {
					includeDetailedAnamnese = false;
					this.setState({includeAnamnese: false});
				}
			});

			getUserInfo().then((response) => {
				if (response.ok) {
					this.setState({
						isLoggedIn: true,
						includeAnamnese: includeDetailedAnamnese,
					});
					begin();
				} else {
					window.location.href = `/`;
				}
			});
		});
	}

	componentWillUnmount() {
		window.addEventListener('resize', this.handleResize);
	}

	handleResize = () => {
		if (window.innerHeight > window.innerWidth) {
			this.setState({isPortrait: true});
		} else {
			this.setState({isPortrait: false});
		}
	};

	render() {
		const {
			isLoggedIn,
			includeAnamnese,
			isPortrait,
			isRotateDeviceFirstTimeRendered,
		} = this.state;

		if (!isLoggedIn) {
			return null;
		}

		const {currentStepIndex} = this.props;
		if (renderRemainingTests.length) {
			var CurrentStep = renderRemainingTests[currentStepIndex];
		} else {
			var CurrentStep = testSteps[currentStepIndex];
		}
		if (!isPortrait && isRotateDeviceFirstTimeRendered) {
			setTimeout(() => {
				this.setState({isRotateDeviceFirstTimeRendered: false});
			}, 3000);
		}
		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate-logo"
						/>
					</h1>
				</header>
				{isRotateDeviceFirstTimeRendered && !isPortrait && isTablet && (
					<div className="lit-test-rotation-gif">
						<img src={Gif} alt="rotate tablet gif" />
					</div>
				)}
				<div className="lit-wrapper--article text-center">
					<CurrentStep includeLongAnamnese={includeAnamnese} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentStepIndex} = state;

	return {
		currentStepIndex,
	};
};

export default connect(mapStateToProps)(ScreeningTest);
